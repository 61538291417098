"use client";

import { Button } from "@/Common/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/Common/ui/sheet";
import { Menu } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";

export function NavSheetView() {
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();
  const isHome = pathname === "/";

  const closeSheet = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const href = event.currentTarget.getAttribute('href');
    if (href?.startsWith('#') && !isHome) {
      event.preventDefault();
      window.location.href = '/' + href;
    } else if (href?.startsWith('#')) {
      event.preventDefault();
      setIsOpen(false);
      setTimeout(() => {
        const element = document.querySelector(href);
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
    setIsOpen(false);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild className="md:hidden">
        <Button variant="outline" size="icon">
          <Menu className="size-6" />
          <span className="sr-only">Toggle menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="top">
        <nav className="flex flex-col space-y-4">
          <Link
            href={isHome ? "#organizations" : "/#organizations"}
            className="text-foreground hover:text-primary"
            onClick={closeSheet}
          >
            Organizations
          </Link>
          <Link
            href={isHome ? "#services" : "/#services"}
            className="text-foreground hover:text-primary"
            onClick={closeSheet}
          >
            Services
          </Link>
          <Link
            href={isHome ? "#tech-stack" : "/#tech-stack"}
            className="text-foreground hover:text-primary"
            onClick={closeSheet}
          >
            Tech Stack
          </Link>
          <Link
            href="/jobs"
            className="text-foreground hover:text-primary"
            onClick={closeSheet}
          >
            Jobs
          </Link>
          <Button variant="outline" asChild>
            <Link
              href={isHome ? "#contact" : "/#contact"}
              onClick={closeSheet}
            >
              Get in touch
            </Link>
          </Button>
        </nav>
      </SheetContent>
    </Sheet>
  );
}
